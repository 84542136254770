/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap-grid-only-css/dist/css/bootstrap-grid.min.css";

html, body { height: 100%; position:absolute;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; width: 100vw}


.form-field{
    width: 100%;
}

.mat-radio-button {
    margin-left: 10px;
    margin-bottom: 5px;
}